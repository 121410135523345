// Libs.
import React from 'react';
// Deps.
import AppLayout from '../../components/layout/AppLayout';
import SEO from '../../components/layout/seo';
import getWindow from '../../libs/getWindow'

const location = getWindow('location');
if (location && (location.pathname === "/app" || location.pathname === "/app/")) {
  location.replace(`/app/rewards`) // highlight-line
}

const AppLoginPage = ({ location }) => {
  return (
    <AppLayout>
      <SEO title="User Login" />
    </AppLayout>
  );
};

export default AppLoginPage;
